import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from "vue";
import quasarUserOptions from "./quasarUserOptions";
import App from './App.vue';
import router from "./router";
import { key, store } from "./store";
import { authService, machineService, oeeService, productionOrderService, roleService, shiftService, stopCategoryService, stopReasonService } from "./store/services";

import CtPassword from '@/shared/components/CtPassword.vue';
import CtPage from '@/shared/components/CtPage.vue';
import ConfigMenuItem from '@/shared/components/ConfigMenuItem.vue';

roleService.fillRoles(store);
authService.tryLogonFromStorage();

async function initialize() {
    await stopCategoryService.retrieve(store);
    await machineService.retrieve(store);
    await stopReasonService.retrieve(store);
    await shiftService.retrieve(store);
    await oeeService.retrieveDashboardConfiguration(store);
    await productionOrderService.retrieveProductionOrderPropertyNames();
}

initialize().then(() => {

    const app = createApp(App)
        .use(Quasar, quasarUserOptions)
        .use(store, key)
        .use(router);

    app.component('CtPassword', CtPassword);
    app.component('CtPage', CtPage);
    app.component('ConfigMenuItem', ConfigMenuItem);
    app.mount('#app');

})

