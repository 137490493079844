import { State } from "@/store";
import { Dashboard } from "@/store/Dtos";

const GET_DASHBOARDS_SORTED = "get-dashboards-sorted";

export const OeeGetterNames = {
    GET_DASHBOARDS_SORTED
}

export const OeeGetters = {
    [GET_DASHBOARDS_SORTED]: (state: State) => (): Dashboard[] => {
        return state.dashboards.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        })
    }
}
