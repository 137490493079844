import { State } from "@/store";

const GET_LOGGERS_STATE = "get-loggers-state";
export const LoggersGetterNames = {
  GET_LOGGERS_STATE,
};

export const LoggersGetters = {
  [GET_LOGGERS_STATE]: (state: State) => (): boolean => {
    const hasOfflineLoggers = state.loggerStates.some(l => !l.online);
    return !hasOfflineLoggers;
  },
 
}
