import { State } from "@/store";
import { StopCategoryDto } from "@/store/Dtos";

export const SET_STOPCATEGORIES = "SET-STOPCATEGORIES";
export const ADD_UPDATE_STOPCATEGORY = "ADD-UPDATE-STOPCATEGORY";
export const DELETE_STOPCATEGORY = "DELETE-STOPCATEGORY";

export const stopCategoryMutations = {
    [SET_STOPCATEGORIES](state: State, payload: StopCategoryDto[]): void {
        state.stopCategories = payload;
    },
    [ADD_UPDATE_STOPCATEGORY](state: State, payload: StopCategoryDto): void {
        const match = state.stopCategories.find(sc => sc.id === payload.id);
        if (match === undefined) {
            state.stopCategories.push(payload);
            return;
        }

        match.color = payload.color;
        match.description = payload.description;
        match.name = payload.name;
    },
    [DELETE_STOPCATEGORY](state: State, payload: number): void {
        state.stopCategories = state.stopCategories.filter(sc => sc.id !== payload);
    }
}