import Api from '@/services/api';
import { State } from '@/store';
import { MachineDetail, MachineEditDto } from '@/store/Dtos';
import { Store } from 'vuex';
import * as Mutations from './mutations';

class MachineService {
    async retrieve(store: Store<State>) : Promise<MachineDetail[]> {
        const { data } = await Api().get<MachineDetail[]>('/machines');
        store.commit(Mutations.SET_MACHINES, data);
        return data;
    }

    async save(store: Store<State>, machineId: string, machine: MachineEditDto): Promise<void> {
        await Api().put<MachineDetail>(`/machines/${machineId}`, machine);
        await this.retrieve(store);
    }
}

export const machineService = new MachineService();