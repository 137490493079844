import Api from '@/services/api';
import { State } from '@/store';
import { RoleDto, UserDto, UserGroupAddDto, UserGroupDto, UserGroupEditDto } from "@/store/Dtos";
import { Store } from 'vuex';
import { authService } from '../authentication/AuthService';
import * as Mutations from './mutations';

interface UserGroupDetail {
    id: string;
    name: string;
    roles: RoleDto[]
    users: UserDto[]
}

class UserGroupService {
    async retrieve(store: Store<State>): Promise<UserGroupDto[]> {
        const resp = await Api().get<UserGroupDto[]>("/usergroups");
        store.commit(Mutations.SET_USERGROUPS, resp.data);
        return resp.data;
    }
    async create(store: Store<State>, model: UserGroupAddDto): Promise<void> {
        const resp = await Api().post<UserGroupDetail>("/usergroups", model);
        const userGroup = {
            id: resp.data.id,
            name: resp.data.name,
            roleNames: resp.data.roles.map(r => r.name),
            users: resp.data.users.map(u => u.id)
        };
        store.commit(Mutations.ADD_UPDATE_USERGROUP, userGroup);
    }
    async update(store: Store<State>, id: number, model: UserGroupEditDto) {

        const resp = await Api().put<UserGroupDetail>(`/usergroups/${id}`, model);
        const userGroup = {
            id: resp.data.id,
            name: resp.data.name,
            roleNames: resp.data.roles.map(r => r.name),
            users: resp.data.users.map(u => u.id)
        };
        store.commit(Mutations.ADD_UPDATE_USERGROUP, userGroup);

        // if the usergroup is the user group of the current user, update token
        const userGroupIds = store.state.tokenInfo?.userGroupIds;
        
        if (userGroupIds === undefined)
            return;
        if (!userGroupIds.includes(id))
            return;
        await authService.refreshToken();
    }
    async delete(store: Store<State>, id: number) {
        await Api().delete(`/usergroups/${id}`);
        store.commit(Mutations.DELETE_USERGROUP, id);
    }
}

export const userGroupService = new UserGroupService();

// import store from "@/store/index";
// import Api from "@/services/api";

// import { UserGroupAddDto, UserGroupEditDto } from '../../../system/models/user-group';

// export const UserGroupService = {
//   getList: async () => {

//   },
//   get: async (id: number) => {
//     const resp = await Api().get(`/usergroups/${id}`);
//     return resp.data;
//   },



//   getUsers: async (id: number) => {
//     const resp = await Api().get(`/usergroups/${id}/users`);
//     return resp.data;
//   },
//   addUser: async (id: number, userId: number) => {
//     const resp = await Api().post(`/usergroups/${id}/users?userId=${userId}`);
//     return resp.data;
//   },
//   deleteUser: async (id: number, userId: number) => {
//     const resp = await Api().delete(`/usergroups/${id}/users?userId=${userId}`);
//     return resp.data;
//   }
// }