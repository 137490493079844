import { State } from "@/store";
import { ProductionOrderPropertyNames } from "@/store/models";

export const UPDATE_PRODUCTION_ORDER_PROPERTY_NAME = "UPDATE-PRODUCTION-ORDER-PROPERTY-NAME";
export const UPDATE_PRODUCTION_ORDER_PROPERTY_NAMES = "UPDATE-PRODUCTION-ORDER-PROPERTY-NAMES";

export const productionOrderMutations = {
    [UPDATE_PRODUCTION_ORDER_PROPERTY_NAME](state: State, payload: [ProductionOrderPropertyNames, string]): void {
        state.orderPropertyNameTranslations.set(payload[0], payload[1]);
    },
    [UPDATE_PRODUCTION_ORDER_PROPERTY_NAMES](state: State, payload: Map<ProductionOrderPropertyNames, string>): void {
        payload.forEach((value, key) => {
            state.orderPropertyNameTranslations.set(key, value);
        })
    },
}