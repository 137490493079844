

import { State } from "@/store";
import { ShiftDto } from "@/store/Dtos";

function shiftCompare(a: ShiftDto, b: ShiftDto): number {
    if (a.index < b.index) return -1;
    if (a.index > b.index) return 1;
    return 0;
}

const GET_SHIFTS_ORDERED = "get-shifts-ordered";

export const ShiftGetterNames = {
    GET_SHIFTS_ORDERED
};

export const ShiftGetters = {
    [GET_SHIFTS_ORDERED]: (state: State) => (): ShiftDto[] => {
        return state.shifts.sort(shiftCompare);
    }
}

