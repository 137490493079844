import Api from "@/services/api";
import { State } from "@/store";
import { StopReasonAddDto, StopReasonDto, StopReasonEditDto } from "@/store/Dtos";
import { Store } from "vuex";
import * as Mutations from './mutations';

class StopReasonService {

    async retrieve(store: Store<State>) {
        const { data } = await Api().get<StopReasonDto[]>("/stopReasons");
        store.commit(Mutations.SET_STOP_REASONS, data);
    }

    async editStopReason(store: Store<State>, id: string, model: StopReasonEditDto
    ): Promise<void> {
        await Api().put(`/stopReasons/${id}`, model);
        await this.retrieve(store);
    }

    public async deleteStopReason(store: Store<State>, id: string): Promise<void> {
        await Api().delete(`/stopReasons/${id}`);
        await this.retrieve(store);
    }

    public async addStopReason(store: Store<State>, model: StopReasonAddDto): Promise<void> {
        await Api().post("/stopReasons", model);
        await this.retrieve(store);
    }

}

export const stopReasonService = new StopReasonService();