import { State } from "@/store";
import { UserGroupDto } from "@/store/Dtos";

export const SET_USERGROUPS = "SET-USERGROUPS";
export const ADD_UPDATE_USERGROUP = "ADD-UPDATE-USERGROUP";
export const DELETE_USERGROUP = "DELETE-USERGROUP";

export const UsergroupMutations = {
    [SET_USERGROUPS](state: State, payload: UserGroupDto[]) : void {
        state.userGroups = payload;
    },
    [ADD_UPDATE_USERGROUP](state: State, payload: UserGroupDto): void {
        const match = state.userGroups.find(ug => ug.id === payload.id);
        if (match === undefined) {
            state.userGroups.push(payload);
            return;
        }

        match.name = payload.name;
        match.roleNames = payload.roleNames;
    },
    [DELETE_USERGROUP](state: State, payload: number): void {
        state.userGroups = state.userGroups.filter(ug => ug.id !== payload);
    }

}