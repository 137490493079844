import api from "@/services/api";
import { store } from "@/store";
import { SettingDto } from "@/store/Dtos";
import { ProductionOrderDetailDto } from "@/store/Dtos/ProductionOrders";
import { ProductionOrderProperties, ProductionOrderPropertyNames } from "@/store/models";
import * as Mutations from './mutations';

const SETTING_KEY = "production-order";

class ProductionOrder {

    async get(productionOrderId: string): Promise<ProductionOrderDetailDto> {
        const { data } = await api().get<ProductionOrderDetailDto>(`productionOrders/${productionOrderId}`);
        return data;
    }
    async setProductionOrderPropertyNames(values: Map<ProductionOrderPropertyNames, string>): Promise<void> {
        values.forEach(async (value, key) => {
            await api().post("settings", {
                type: SETTING_KEY,
                key: key as string,
                value: value
            } as SettingDto)

            store.commit(Mutations.UPDATE_PRODUCTION_ORDER_PROPERTY_NAME, [key, value]);
        })

    }

    async retrieveProductionOrderPropertyNames(): Promise<void> {
        const { data } = await api().get<SettingDto[]>(`settings/${SETTING_KEY}`);
        const map = new Map<ProductionOrderPropertyNames, string>();

        data.forEach((d) => {
            if (d.key in ProductionOrderProperties)
                map.set(d.key as ProductionOrderPropertyNames, d.value);
        });
        
        store.commit(Mutations.UPDATE_PRODUCTION_ORDER_PROPERTY_NAMES, map);
    }
}

export const productionOrderService = new ProductionOrder();