import { State } from "@/store";
import { StopReasonDto } from "@/store/Dtos";

const GET_STOP_REASONS_FOR_MACHINE = "get-stop-reasons-for-machine";

export const StopReasonsGetterNames = {
    GET_STOP_REASONS_FOR_MACHINE,
};

export const StopReasonGetters = {
    [GET_STOP_REASONS_FOR_MACHINE]: (state: State) => (machineId: string): StopReasonDto[] => {
        const machineSpecific = state.stopReasons.filter(sr => sr.machineId === machineId);
        const generic = state.stopReasons.filter(sr => sr.isGenericStopReason);

        const mergedAndSorted = generic.concat(machineSpecific)
        .sort(a => a.stopReasonNumber);

        return mergedAndSorted;
    },
};