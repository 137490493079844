import * as Mutations from './mutations';
import { store } from '@/store';
import { StopsFilter } from "@/store/models";
import { StopDto } from '@/modules/stops/models';
import Api from '@/services/api';

class StopsService {
    private url = '/machineStops';

    public async setFilter(filter: StopsFilter | null) {
        store.commit(Mutations.SET_STOPS_FILTER, filter);
    }

    public async get(
        onlyUnprocessed: boolean,
        machineId: string | null,
        from: number | null,
        until: number | null,
    ): Promise<StopDto[]> {
        const result = (await Api().get<StopDto[]>(`${this.url}`, {
            params: {
                onlyUnprocessed,
                machineId,
                from,
                until,
            },
        }));

        return result.data;
    }

    //   public async editStop(id: number, model: StopEditDto): Promise<void> {
    //     return await Api().put(`${this.url}/${id}`, model);
    //   }


    //   public async editStopReason(
    //     id: string,
    //     model: StopReasonEditDto,
    //   ): Promise<void> {
    //     await Api().put(`/stopReasons/${id}`, model);
    //   }

    //   public async deleteStopReason(id: string) {
    //     await Api().delete(`/stopReasons/${id}`);

    //   }

    //   public async addStopReason(model: StopReasonAddDto): Promise<void> {
    //     await Api().post('/stopReasons', model);
    //   }


    //   public async addStopCategory(model: StopCategoryAddDto): Promise<StopCategoryDto> {
    //     const result = await Api().post<StopCategoryDto>(`/stopCategories`, model);
    //     return result.data;
    //   }

    //   public async editStopCategory(id: number, model: StopCategoryEditDto): Promise<StopCategoryDto> {
    //     const result = await Api().put<StopCategoryDto>(`/stopCategories/${id}`, model);
    //     return result.data;
    //   }

    //   public async deleteStopCategory(id: number): Promise<void> {
    //     await Api().delete(`/stopCategories/${id}`);
    //   }
}

export const stopsService = new StopsService();