import Api from '@/services/api';
import { State } from '@/store';
import { ShiftBatchEditDto, ShiftDto } from '@/store/Dtos';
import { Store } from 'vuex';
import * as Mutations from './mutations';

class ShiftService {
    public async retrieve(store: Store<State>) {
        const { data } = await Api().get<ShiftDto[]>('/Shifts');
        store.commit(Mutations.SET_SHIFTS, data);
    }

    public async batchUpdate(store: Store<State>, models: ShiftBatchEditDto[]): Promise<void> {
        await Api().put('/Shifts', models);
        await this.retrieve(store);
    }
}

export const shiftService = new ShiftService();
