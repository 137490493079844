import { State } from "@/store";
import { Dashboard } from "@/store/Dtos";
import { OEEFilter } from "@/store/models/oeeFilter";

export const SET_OEE_FILTER = "SET-OEE-FILTER";
export const SET_DASHBOARDS = "SET-DASHBOARDS";

export const OeeMutations = {
    [SET_OEE_FILTER](state: State, payload: OEEFilter | null) : void{
        state.oeeFilter = payload;
    },
    [SET_DASHBOARDS](state: State, payload: Dashboard[]) : void {
        state.dashboards = payload;
    }
}