import { State, store } from '@/store';
import { Store } from 'vuex';
import * as Mutations from './mutations';
import Api from '@/services/api';
import { StopCategoryAddDto, StopCategoryDto, StopCategoryEditDto } from '@/store/Dtos';

class StopCategoryService {
    async retrieve(store: Store<State>) {
        const { data } = await Api().get<StopCategoryDto[]>(`/stopCategories`);
        store.commit(Mutations.SET_STOPCATEGORIES, data);
    }

    public async addStopCategory(store: Store<State>, model: StopCategoryAddDto): Promise<void> {
        const { data } = await Api().post<StopCategoryDto>(`/stopCategories`, model);
        store.commit(Mutations.ADD_UPDATE_STOPCATEGORY, data);
    }

    public async editStopCategory(
        store: Store<State>,
        id: number,
        model: StopCategoryEditDto
    ): Promise<void> {
        await Api().put<StopCategoryDto>(`/stopCategories/${id}`, model);
        store.commit(Mutations.ADD_UPDATE_STOPCATEGORY, {
            color: model.color,
            description: model.description,
            name: model.name,
            id: id
        } as StopCategoryDto);
    }

    public async deleteStopCategory(id: number): Promise<void> {
        await Api().delete(`/stopCategories/${id}`);
        store.commit(Mutations.DELETE_STOPCATEGORY, id);
    }
}

export const stopCategoryService = new StopCategoryService();