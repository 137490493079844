import { InjectionKey } from 'vue';
import { createStore, Store, useStore } from 'vuex';
import { Dashboard, MachineDetail, RoleDto, ShiftDto, StopCategoryDto, StopReasonDto, TokenResponseDto, UserDto, UserGroupDto } from "./Dtos";
import { mutations } from './parts/mutations';
import { getters } from './parts/getters';
import { OEEFilter } from './models/oeeFilter';
import { ProductionOrderPropertyNames, StopsFilter } from './models';
import { IOptions } from './models/options';
import { LoggerStateDto } from './Dtos/LoggerStateDto';

export interface State {
  tokenInfo: TokenResponseDto | null;
  roles: RoleDto[];
  userGroups: UserGroupDto[];
  users: UserDto[];
  stopCategories: StopCategoryDto[];
  machines: MachineDetail[];
  stopReasons: StopReasonDto[];
  shifts: ShiftDto[];
  oeeFilter: OEEFilter | null;
  dashboards: Dashboard[];
  orderPropertyNameTranslations: Map<ProductionOrderPropertyNames, string>,
  stopsFilter: StopsFilter | null;
  options: IOptions;
  loggerStates: LoggerStateDto[];
}
export const key: InjectionKey<Store<State>> = Symbol();

export const getStore = (): Store<State> => useStore(key);
export const store = createStore<State>({
  state: {
    tokenInfo: null,
    roles: [],
    userGroups: [],
    users: [],
    stopCategories: [],
    machines: [],
    stopReasons: [],
    shifts: [],
    oeeFilter: null,
    dashboards: [],
    orderPropertyNameTranslations: new Map(),
    stopsFilter: null,
    options: {
      showStops: true,
      showOperatorModule: true
    },
    loggerStates: []
  },
  mutations,
  getters
});

