import api from "@/services/api";
import { store } from "@/store";
import { LoggerStateDto } from "@/store/Dtos/LoggerStateDto";
import * as Mutations from './mutations';

class LoggerService {
    async updateLoggerStates() : Promise<LoggerStateDto[]> {
        const response = await api().get<LoggerStateDto[]>("/loggers");
        store.commit(Mutations.SET_LOGGERSTATE, response.data);

        return response.data;
    }
}

export const loggerService = new LoggerService();