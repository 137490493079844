import { State } from '@/store';
import { DashboardConfiguration, OeeResponseDetailDto, OeeResponseDto, StopReasonsResponseForPieChartDto } from '@/store/Dtos';
import { OEEFilter } from '@/store/models/oeeFilter';
import { Moment } from 'moment';
import { Store } from 'vuex';
import * as Mutations from './mutations';
import Api from '@/services/api';
import qs from 'qs';
import { OEEDashboardShiftDto } from '@/store/Dtos/OEEDashboardShiftDto';
import moment from 'moment';

class OEEService {

    setFilter(store: Store<State>, filter: OEEFilter | null) {
        store.commit(Mutations.SET_OEE_FILTER, filter);
    }

    public async retrieveDashboardConfiguration(store: Store<State>) {
        const now = moment().format("YYYYMMDDHHmmss")

        const result = await fetch(`/dashboardConfig.json?t=${now}`);
        const dashboardConfiguration  = (await result.json()) as DashboardConfiguration;
        store.commit(Mutations.SET_DASHBOARDS, dashboardConfiguration.dashboards);
    }

    public async getDashboardOEE(machineIds: string[]) : Promise<OeeResponseDto[]> {
        const response = await Api().get<OeeResponseDto[]>('/oee/OeeForDashboard',  {
            params: {
                machineIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: "repeat"
                });
            }
        });
        
        return response.data;
      }

      public async getShiftDashboardOEE(machineIds: string[]) : Promise<OEEDashboardShiftDto> {
        const response = await Api().get<OEEDashboardShiftDto>('/oee/OEEShiftDashboard',  {
            params: {
                machineIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: "repeat"
                });
            }
        });
        
        return response.data;
      }

    public async getOEE(
        from: Moment,
        until: Moment,
        machineId: string,
        shiftIds: string[]
    ): Promise<OeeResponseDetailDto> {

        const response = await Api().get<OeeResponseDetailDto[]>(`/OEE`, {
            params: {
                from: from.toISOString(),
                until: until.toISOString(),
                machineIds: [machineId],
                shifts: shiftIds,
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: "repeat"
                });
            }

        });
        return response.data[0];
    }

    public async getStopReasons(
        machineId: string,
        from: Moment,
        until: Moment,
        shiftIds: string[]
    ): Promise<StopReasonsResponseForPieChartDto> {


        const result = await Api().get<StopReasonsResponseForPieChartDto>(`/machineStops/piechart`, {
            params: {
                machineId,
                from: from.valueOf(),
                until: until.valueOf(),
                shiftIds
            },
        });

        return result.data;
    }
}

export const oeeService = new OEEService();

