import { State } from "@/store";
import { TokenResponseDto } from "@/store/Dtos";

export const SET_TOKEN = 'SET-TOKEN';

export const AuthenticationMutations = {
    [SET_TOKEN](state: State, payload: TokenResponseDto | null): void {
      state.tokenInfo = payload;
    }
  };
  

