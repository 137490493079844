import { store } from "@/store";
import { ProfileInfo } from "@/store/models";
import { createRouter, createWebHistory, RouteRecord, RouteRecordRaw } from "vue-router";
import { getterNames } from "@/store/parts/getters";
import { configRoutes } from "./parts/config";
import { requiresAuthentication, useLeftDrawer } from "./helpers";
import { ROLES } from "@/shared/services/roles";
import { authService, notifyService } from "@/store/services";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: "/auth",
        component: () => import("@/modules/system/Authenticate.vue"),
    },
    {
        path: '/',
        component: () => import("@/layouts/desktop/Main.vue"),
        redirect: () => '/dashboard',
        children: [
            configRoutes,
            {
                path: "oee",
                ...useLeftDrawer(
                    () => import("@/modules/oee/views/OEE.vue"),
                    () => import("@/modules/oee/views/LeftMenu.vue"),
                    {
                        requiresAuth: true,
                        hasRequiredRoles: (userRoles: string[]) => {
                            return userRoles.some((val) => val === ROLES.CAN_VIEW_OEE);
                        },
                    }
                ),
                props: true,
            },
            {
                path: "dashboard",
                component: () => import("@/modules/dashboard/views/DashboardLanding.vue"),
                ...requiresAuthentication([ROLES.CAN_VIEW_DASHBOARD]),
            },
            {
                path: "dashboard/:id",
                component: () => import("@/modules/dashboard/views/OEEDashboard.vue"),
                ...requiresAuthentication([ROLES.CAN_VIEW_DASHBOARD]),
                props: true
            },
            {
                path: 'orders',
                component: () => import("@/modules/orders/Orders.vue"),
                ...requiresAuthentication([ROLES.CAN_VIEW_ORDERS]),
            },
            {
                path: 'stops',
                ...useLeftDrawer(
                    () => import("@/modules/stops/views/Stops.vue"),
                    () => import("@/modules/stops/LeftPane.vue")
                )
            },
            {
                path: "operatormodule",
                component: () => import("@/modules/operator-module-old/views/Landing.vue"),
                meta: {
                    requiresAuth: true,
                    hasRequiredRoles: (userRoles: string[]) => {
                        return userRoles.some((val) => val === ROLES.OPERATOR_MODULE);
                    }
                }
            },
            {
                path: "operatormodule/:machineId",
                component: () =>
                    import("@/modules/operator-module-old/views/MachinePage.vue"),
                props: true
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


router.beforeEach(async (to, _, next) => {

    if (to.query && to.query.apiKey) {

        try {
            await authService.logonFromApiKey(to.query.apiKey as string);

            delete to.query.apiKey;
            next(to);
            return;
        } catch {
            next('/auth');
            return;
        }
    }

    // Don't check authentication when no authentication is required
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
        next();
        return;
    }

    // The request that was made requires authentication. If the to path is authentication just continue
    if (to.path === "/auth") {
        next();
        return;
    }

    // From here on, you should be authenticated, checking if there are some roles required
    const isAuthenticated = store.getters[getterNames.GET_IS_AUTHENTICATED]();
    if (!isAuthenticated) {
        next('/auth');
        return;
    }

    // Check if no roles are required
    if (!to.matched.some((record) => record.meta.hasRequiredRoles)) {
        next();
        return;
    }

    // From here on, some roles are required

    const userRoles = (store.getters[getterNames.GET_PROFILE_INFO]() as ProfileInfo).roles;
    let isAuthorized = true;


    to.matched.forEach((record: RouteRecord) => {
        if (!record.meta.hasRequiredRoles) return;

        const isAuthorizedForThisRecordFunction = record.meta.hasRequiredRoles as (
            userRoles: string[]
        ) => boolean;
        const isAuthorizedForThisRecord = isAuthorizedForThisRecordFunction(
            userRoles
        );

        if (!isAuthorizedForThisRecord) isAuthorized = false;
    });

    if (isAuthorized) {
        next();
        return;
    }

    notifyService.error("Not enough rights for this page");
    // Should be authorized
    next("/auth");

});

export default router;