import { State } from "@/store";
import { MachineDetail } from "@/store/Dtos";

const getAllMachines = (machine: MachineDetail): MachineDetail[] => {
  let response: MachineDetail[] = [];

  response.push(machine);
  if (machine.subMachines === undefined)
    return response;

  machine.subMachines.forEach((subMachine: MachineDetail) => {
    const subMachineList = getAllMachines(subMachine);

    response = response.concat(subMachineList);
  });

  return response;
};
function getMachinesFlat(state: State): MachineDetail[] {
  let response: MachineDetail[] = [];

  state.machines.forEach((rootMachine: MachineDetail) => {
    response = response.concat(getAllMachines(rootMachine));
  });
  return response;
}

const GET_MACHINES_FLAT = "get-machines-flat";
const GET_MACHINE_BY_ID = "get-machine-by-id";
export const MachineGetterNames = {
  GET_MACHINES_FLAT,
  GET_MACHINE_BY_ID,
};



export const MachineGetters = {
  [GET_MACHINES_FLAT]: (state: State) => (): MachineDetail[] => {
    return getMachinesFlat(state);
  },
  [GET_MACHINE_BY_ID]: (state: State) => (id: string): MachineDetail | undefined => {
    const machines = getMachinesFlat(state);
    return machines.find(m => m.id.toLocaleLowerCase() === id.toLocaleLowerCase());
  }
}
