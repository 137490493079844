import { State } from "@/store";
import { MachineDetail } from "@/store/Dtos";

export const SET_MACHINES = "SET-MACHINES";
export const SET_MACHINE = "SET-MACHINE";

export const machineMutations = {
    [SET_MACHINES](state: State, payload: MachineDetail[]): void {
        state.machines = payload;
    },
    [SET_MACHINE](state: State, payload: MachineDetail): void {
        state.machines = [
            ...state.machines.filter(m => m.id !== payload.id),
            payload
        ];
    }
}