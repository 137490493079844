import axios, { AxiosInstance } from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';;
import { store } from '@/store';

export default (): AxiosInstance => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "",
  };



  const regex = /^(https?:\/\/)?([\w.-]*)(:\d+)?(:\/[.]*)?/;
  if (window.location.href.match(regex) === null)
    throw new Error("Can't determine path");
  const match = window.location.href.match(regex);

  if (match === null)
    throw new Error("Could not find base url from uri");
  const apiUrl = match[0].toString() + "/api";
  const apiUrlDev: string = process.env.VUE_APP_API;

  const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? apiUrlDev : apiUrl,
    withCredentials: false,
    headers,
  });

  const notifyError = (message: string, caption?: string) => {
    Notify.create({
      type: "negative",
      message: message,
      caption: caption,
    });
  };

  instance.interceptors.request.use(function (config) {
    if (store.state.tokenInfo !== null) {
      config.headers.Authorization = "Bearer " + store.state.tokenInfo.accessToken;
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const response = error.response;
      const data = response.data;
      if (!data) {
        notifyError("Unknown error");
        return;
      }

      notifyError(data.title, data.detail);
    }
  );

  return instance;
};
