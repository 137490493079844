import { IAlertService } from '@/layouts/components/CtAdvancedQueryList/interfaces/IAlertService';
import Notify from 'quasar/src/plugins/Notify.js';

class NotifyService implements IAlertService {
    info(message: string): void {
        Notify.create({
            message,
            type: 'positive'
        })
    }

    error(message: string){
        Notify.create({
            message,
            type: 'negative'
        })
    }
}

export const notifyService = new NotifyService();
