import SessionStorage from 'quasar/src/plugins/SessionStorage.js';;
import { TokenResponseDto } from "../../Dtos";
const TOKEN_KEY = 'token-info';
class Storage {
    tryGetTokenFromStorage(): TokenResponseDto | null {
        return SessionStorage.getItem<TokenResponseDto>(TOKEN_KEY);
    }
    setToken(token: TokenResponseDto) {
        SessionStorage.set(TOKEN_KEY, token);
    }
    clearToken() {
        SessionStorage.remove(TOKEN_KEY);
    }
}

export const storageService = new Storage();