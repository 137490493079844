import { AuthenticationGetterNames, AuthenticationGetters } from "./authentication/getters";
import { LoggersGetterNames, LoggersGetters } from "./loggers/getters";
import { StopReasonsGetterNames, StopReasonGetters } from "./stopReasons/getters";
import { ShiftGetterNames, ShiftGetters } from "./shifts/getters";
import { MachineGetterNames, MachineGetters } from "./machines/getters";
import { OeeGetterNames, OeeGetters } from "./oee/getters";
import { ProductionOrderGetterNames, ProductionOrderGetters } from "./productionOrders/getters";
import { StopCategoryGetterNames, StopCategoryGetters } from "./stopCategories/getters";

export const getters = {
    ...AuthenticationGetters,
    ...LoggersGetters,
    ...StopReasonGetters,
    ...StopCategoryGetters,
    ...ShiftGetters,
    ...MachineGetters,
    ...OeeGetters,
    ...ProductionOrderGetters
}

export const getterNames = {
    ...AuthenticationGetterNames,
    ...LoggersGetterNames,
    ...StopReasonsGetterNames,
    ...StopCategoryGetterNames,
    ...ShiftGetterNames,
    ...MachineGetterNames,
    ...OeeGetterNames,
    ...ProductionOrderGetterNames
}