import { AuthenticationMutations } from "./authentication/mutations"
import { loggerMutations }from './loggers/mutations';
import { UserMutations } from "./users/mutations";
import { roleMutations } from "./roles/roleMutations";
import { UsergroupMutations } from "./usergroups/mutations";
import { stopCategoryMutations } from "./stopCategories/mutations";
import { machineMutations } from "./machines/mutations";
import { stopReasonMutations } from "./stopReasons/mutations";
import { shiftMutations } from "./shifts/mutations";
import { OeeMutations } from "./oee/mutations";
import { productionOrderMutations } from "./productionOrders/mutations";
import { stopsMutations } from "./stops/mutations";
export const mutations = {
  ...AuthenticationMutations,
  ...loggerMutations,
  ...UserMutations,
  ...roleMutations,
  ...UsergroupMutations,
  ...stopCategoryMutations,
  ...machineMutations,
  ...stopReasonMutations,
  ...shiftMutations,
  ...OeeMutations,
  ...productionOrderMutations,
  ...stopsMutations,
};