import { State } from "@/store";
import { ProductionOrderPropertyNames, ProductionOrderProperties } from "@/store/models";

const GET_PRODUCTION_ORDER_PROPERTY_NAME = "get-production-order-property-name";

export const ProductionOrderGetterNames = {
    GET_PRODUCTION_ORDER_PROPERTY_NAME,
};

export const ProductionOrderGetters = {
    [GET_PRODUCTION_ORDER_PROPERTY_NAME]: (state: State) => (property: ProductionOrderPropertyNames): string => {
        const match = state.orderPropertyNameTranslations.get(property);
        return (match === undefined) ? ProductionOrderProperties[property]  : match;
    },
}
