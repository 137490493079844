import { ROLES } from "@/shared/services/roles";
import { State } from "@/store";
import { RoleDto } from "@/store/Dtos";
import * as Mutations from './roleMutations';
import { Store } from "vuex";

class RoleService {
    fillRoles(store: Store<State>) {
        const roles: RoleDto[] = [];
        Object.entries(ROLES).forEach((value: [string, string]) => {
            const roleName = value[1];
            this.addRole(roles, roleName);
        });

        store.commit(Mutations.SET_ROLES, roles);
    }

    private addRole(roles: RoleDto[], name: string) {
        const description = name;
        roles.push({
            name,
            description
        });
    }
}

export const roleService = new RoleService();