import Api from '@/services/api';
import { store } from '@/store';
import { TokenResponseDto } from '../../Dtos';
import { storageService } from '../storage/storageService';
import * as Mutations from './mutations';

class AuthService {
    async logon(username: string, password: string): Promise<void> {
        const resp = await Api().post<TokenResponseDto>('/auth/token', { username, password });
        store.commit(Mutations.SET_TOKEN, resp.data);
        storageService.setToken(resp.data);
    }

    async refreshToken(): Promise<void> {

        const refreshToken = store.state.tokenInfo?.refreshToken;
        if (refreshToken === undefined)
            return;


        const response = await Api().post("/auth/token/refresh", {
            refreshToken
        });

        store.commit(Mutations.SET_TOKEN, response.data);
        storageService.setToken(response.data);
    }
    async logonFromApiKey(apiKey: string): Promise<void> {

        const { data } = await Api().post("/auth/token/apikey", {}, {
            params: {
                apiKey
            }
        });

        store.commit(Mutations.SET_TOKEN, data);
        storageService.setToken(data);
    }
    tryLogonFromStorage(): boolean {
        const token = storageService.tryGetTokenFromStorage();
        if (token === null)
            return false;
        store.commit(Mutations.SET_TOKEN, token);
        return true;
    }

    logOff() {
        store.commit(Mutations.SET_TOKEN, null);
        storageService.clearToken();
    }
}
export const authService = new AuthService();
