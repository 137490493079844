import { State } from "@/store";
import { UserDto } from "@/store/Dtos";
import { ProfileInfoUpdate } from "@/store/models";
import { storageService } from "../storage/storageService";

export const SET_PROFILE_INFO = 'SET-PROFILE-INFO';
export const SET_USERS = "SET-USERS";
export const ADD_UPDATE_USER = "ADD-UPDATE-USER";
export const DELETE_USER = "DELETE-USER";

export const UserMutations = {
  [SET_PROFILE_INFO](state: State, payload: ProfileInfoUpdate): void {
    if (state.tokenInfo === null)
      return;

    state.tokenInfo.firstName = payload.firstName;
    state.tokenInfo.lastName = payload.lastName;
    state.tokenInfo.email = payload.email;
    state.tokenInfo.username = payload.username;

    storageService.setToken(state.tokenInfo);
  },
  [SET_USERS](state: State, payload: UserDto[]): void {
    state.users = payload;
  },
  [ADD_UPDATE_USER](state: State, payload: UserDto): void {
    const match = state.users.find(u => u.id === payload.id);
    if (match === undefined) {
      state.users.push(payload);
      return;
    }
    match.email = payload.email;
    match.firstName = payload.firstName;
    match.lastName = payload.lastName;
    match.userGroupId = payload.userGroupId;
    match.username = payload.username;
  },
  [DELETE_USER](state: State, payload: number) : void {
    state.users = state.users.filter(u => u.id !== payload);
  }


};


