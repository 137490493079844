import Api from '@/services/api';
import { State } from '@/store';
import { ApiKeyDto, UserAddDto, UserDto, UserEditDto } from '@/store/Dtos';
import { Store } from 'vuex';
import { authService } from '../authentication/AuthService';
import { userGroupService } from '../usergroups/usergroupsService';
import * as Mutations from './mutations';
class UserService {

    async retrieve(store: Store<State>): Promise<void> {
        const resp = await Api().get<UserDto[]>("/users");

        store.commit(Mutations.SET_USERS, resp.data);
    }

    async create(store: Store<State>, model: UserAddDto): Promise<void> {
        const { data } = await Api().post<UserDto>("/users", model);
        store.commit(Mutations.ADD_UPDATE_USER, data);

        userGroupService.retrieve(store);
    }
    async update(store: Store<State>, id: number, model: UserEditDto): Promise<void> {
        const { data } = await Api().put<UserDto>(`/users/${id}`, model);

        store.commit(Mutations.ADD_UPDATE_USER, data);

        if (store.state.tokenInfo?.id === id)
            await authService.refreshToken();

        userGroupService.retrieve(store);
    }
    async delete(store: Store<State>, id: number) {
        await Api().delete(`/users/${id}`);
        store.commit(Mutations.DELETE_USER, id);

        userGroupService.retrieve(store);
    }
    async changePassword(currentPassword: string, newPassword: string, confirmNewPassword: string) {
        await Api().post(`/users/me/changePassword`, { currentPassword, newPassword, confirmNewPassword });
    }
    async getApiKey() : Promise<string | null>{
        const { data } = await Api().get<ApiKeyDto>('/users/me/apiKey');
        return data.key;
    }
    async renewApiKey() : Promise<string> {
        const {data} = await Api().post<ApiKeyDto>('/users/me/apiKey');
        if (data.key === null)
            throw new Error("Renew key is invalid");
        return data.key;
    }
    async deleteApiKey() : Promise<void> {
        await Api().delete('/users/me/apiKey');
    }
}

export const userService = new UserService();

