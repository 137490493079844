

export const ProductionOrderProperties = {
    productionOrderId: "Production Order Id",
    salesOrderId: "Sales Order Id",
    product: "Product",
    extraField1: "Extra field 1",
    extraField2: "Extra field 2",
    extraField3: "Extra field 3",
    extraField4: "Extra field 4",
    extraField5: "Extra field 5",
    extraField6: "Extra field 6",
    extraField7: "Extra field 7",
    extraField8: "Extra field 8",
    extraField9: "Extra field 9",
    extraField10: "Extra field 10",
    extraParameters: "Extra parameters",
}

export type ProductionOrderPropertyNames = keyof typeof ProductionOrderProperties;