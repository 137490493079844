/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any*/

import { Component } from "vue";

export function requiresAuthentication(requiredRoles?: string[], meta?: any | undefined) {
    return {
        meta: {
            ...meta,
            requiresAuth: true,
            hasRequiredRoles: (userRoles: string[]) => {
                if (requiredRoles === undefined)
                    return true;
                return requiredRoles.every(ur => userRoles.includes(ur));
            }
        }
    };


}

export function useLeftDrawer(main: Component, leftDrawer: Component, meta?: any | undefined) {
    const result = {
        components: {
            default: main,
            leftDrawer,
        },
        meta: {},
    };

    if (meta === undefined)
        meta = {};

    result.meta = {
        ...meta,
        withLeftDrawer: true,
    }


    return result;
}