import { State } from "@/store";
import { ProfileInfo } from "@/store/models";

const GET_IS_IN_ROLE = "is-in-role";
const GET_PROFILE_INFO = "profile-info";
const GET_IS_AUTHENTICATED = "is-authenticated";
const GET_ROLES = "get-roles";

export const AuthenticationGetterNames = {
  GET_IS_IN_ROLE,
  GET_PROFILE_INFO,
  GET_IS_AUTHENTICATED,
};

export const AuthenticationGetters = {
    [GET_PROFILE_INFO]: (state: State) => (): ProfileInfo | null => {
      if (state.tokenInfo === null) {
        return null;
      }
      return {
        firstName: state.tokenInfo.firstName,
        lastName: state.tokenInfo.lastName,
        email: state.tokenInfo.email,
        username: state.tokenInfo.username,
        roles: state.tokenInfo.roles,
        id: state.tokenInfo.id,
        userGroupIds: state.tokenInfo.userGroupIds
      } as ProfileInfo;
    },
    [GET_IS_IN_ROLE]: (state: State) => (role: string): boolean => {
      if (state.tokenInfo === null) {
        return false;
      }
  
      return (
        state.tokenInfo.roles.some((value) => {
          return value === role;
        }) === true
      );
    },
    [GET_IS_AUTHENTICATED]: (state: State) => (): boolean => {
      return state.tokenInfo !== null;
    },
    [GET_ROLES]:(state: State) => (): string[] => {
      return state.tokenInfo?.roles ?? [];
    }
  };