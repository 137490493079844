/* eslint-disable @typescript-eslint/explicit-module-boundary-types*/
import { ROLES } from '@/shared/services/roles';
import { requiresAuthentication, useLeftDrawer } from '../helpers';

export const configRoutes = {
    path: '/config',
    redirect: '/config/profile',
    ...useLeftDrawer(
        () => import('@/modules/config/views/ConfigLanding.vue'),
        () => import('@/modules/config/views/LeftPane.vue'),
    ),
    children: [
        {
            path: "profile",
            component: () => import("@/modules/config/views/ConfigProfile.vue"),
        },
        {
            path: "users",
            component: () =>
                import("@/modules/config/views/ConfigUserManagement.vue"),
            meta: {
                hasRequiredRoles: (userRoles: string[]) => {
                    return userRoles.some(
                        (val) => val === ROLES.CAN_MANAGE_USERS
                    );
                },
            },
        },
        {
            path: "stopCategories",
            component: () => import("@/modules/config/views/StopCategoriesConfig.vue"),
        },
        {
            path: "machines",
            component: () =>
                import("@/modules/config/views/MachinesConfig.vue"),
            meta: {
                hasRequiredRoles: (userRoles: string[]) => {
                    return userRoles.some(
                        (val) => val === ROLES.CAN_EDIT_MACHINES
                    );
                },
            },
        },

        {
            path: "shifts",
            component: () => import("@/modules/config/views/ShiftConfig.vue"),
            meta: {
                hasRequiredRoles: (userRoles: string[]) => {
                    return userRoles.some(role => role === ROLES.CAN_EDIT_SHIFTS);
                }
            }
        },
        {
            path: "productionOrders",
            component: () => import("@/modules/config/views/ConfigProductionOrderPropertyNames.vue"),
            ...requiresAuthentication([ROLES.CAN_VIEW_ORDERS]),
        },
        {
            path: 'loggers',
            component: () => import("@/modules/config/views/ConfigLoggers.vue")
        }
    ]
}