import { State } from "@/store";
import { StopCategoryDto } from "@/store/Dtos";

export const GET_STOP_CATEGORIES = "GET-STOP-CATEGORIES";

export const StopCategoryGetterNames = {
    GET_STOP_CATEGORIES,
};

export const StopCategoryGetters = {
    [GET_STOP_CATEGORIES]: (state: State): StopCategoryDto[] => {
        return state.stopCategories;
    },
};